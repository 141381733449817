
const About = () => {

  return (
    <div className="flex-col">
      <h1>About Page</h1>
      <span className="about">There are two types of companies: hoarders and givers. RAWG is the largest video game database and game discovery service. And we are gladly sharing our 500,000+ games, search, and machine learning recommendations with the world. Learn what the RAWG games database API can do and build something cool with it!</span>
    </div>
  )
}

export default About
